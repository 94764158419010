
                                    $static-content-cache-base-url: "https://s3-ap-southeast-2.amazonaws.com/prod-automait-public-website-content";
                                    $cloud-front-base-url: "https://dr1k2g3wmnols.cloudfront.net";
                                
//===================================================
@import '../../core';

//Theme Colours
$color-theme: #00263e;
$color-theme--darker: #001f31;
$color-button: #ff9c22;
$color-button--hover: #F5931F;
$color-button-text: $color-white;

//===================================================
//Main nav themes
$nav-color: $color-theme;
$nav-mobile-color: $color-theme;
$nav-title-color: $color-black-500;
$nav-bg-color: $color-white;
$nav-br-color: $color-white;
$nav-toggle-color: $color-theme;
$nav-active-br-color: $color-black-50;
$nav-toggle-bg-color: $color-white;
$nav-hover-color: $color-white;
$nav-hover-bg-color: $color-theme;
$nav-logo: $static-content-cache-base-url + "/images/logos/brands/ikdpt-logo.svg";
$nav-logo-mobile: $static-content-cache-base-url + "/images/logos/brands/mob-ikdpt-logo.svg";

//Contact Nav themes
$contact-nav-bg-color: $color-theme;
$contact-nav-color: $color-white;
$contact-nav-br-color: $color-theme;

//Sub Nav themes
$sub-nav-bg-color: $color-white;
$sub-nav-br-color: $color-black-50;
$sub-nav-hover-color: $color-theme;
$sub-nav-hover-bg-color: $color-black-50;
$sub-nav-title-color: $color-black-500;
$sub-nav-desc-color: $color-black-500;
$sub-nav-carousel-theme-item-color: $color-grey;
$sub-nav-carousel-theme-hover-item-color: $color-white;
$sub-nav-mobile-active-title-color: $color-theme;

//Colour for headers across the site (.header--theme-color)
$header-color: $color-black-500;

//Colours for cards
$card-header-color: $header-color;
$card-price-color: $header-color;
$card-icon-color: $header-color;

//Colours for the Main header on content pages
$header-title-color: $color-white;
$header-sub-title-color: $color-white;
$header-gradient-color-left: $color-theme;
$header-gradient-color-right: #002f4b;

//Colours for the VDP page header
$header-VDP-title-color: $color-black-500;
$header-VDP-gradient-color-left: #FBFBFB;
$header-VDP-gradient-color-right: #ECECEC;

//Colour for the sticky footer elements and VDP header
$sticky-footer-background-color: $color-black-800;
$form-header-color: $color-black-800;

@import "../../../src/application";